<script>
import BrowseProducts from '@/graphql/adshop/browseProducts.graphql';
import { QueryHelper } from '@/components/Core/Helpers';

export default {
  mixins: [QueryHelper],
  props: {
    rateClass: {
      type: String,
      required: true
    },
    selectedPath: {
      type: Array,
      default: () => []
    },
    sortBy: {
      type: String,
      required: true
    },
    startDate: {
      type: Date,
      default: null
    },
    endDate: {
      type: Date,
      default: null
    }
  },
  query(props) {
    return {
      query: BrowseProducts,
      variables: {
        rateClass: props.rateClass,
        selectedPath: props.selectedPath,
        sortBy: props.sortBy,
        startDate: props.startDate,
        endDate: props.endDate,
        slug: this.slug
      }
    };
  },
  computed: {
    slug() {
      return this.$store.getters['adShop/slug'];
    }
  }
};
</script>
